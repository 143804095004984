import React from "react"
import { ADD_MODE, useFormMode } from "../components/form"
import { AddSchedaProgetto, EditSchedaProgetto, ViewSchedaProgetto } from "../components/scheda"

const SchedaProgettoPage = ({location}) => {
    const id = location.state?.id || null
    const mode = location.state?.mode || ADD_MODE
    
    const Add = () => <AddSchedaProgetto/>
    const Edit = () => <EditSchedaProgetto id={id}/>
    const View = () => <ViewSchedaProgetto id={id}/>

    const Form = useFormMode(
        Add,
        Edit,
        View,
        mode
    )

    return <Form/>
}

export default SchedaProgettoPage
